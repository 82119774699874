import axios from "axios";

interface SimpleCountry {
  code2: string;
  name: string;
}

interface SimpleRegion {
  id: number;
  name: string;
}

export interface City {
  id: number;
  slug: string;
  display_name: string;
  county: SimpleCountry;
  region: SimpleRegion;
}

interface SearchCityResponse {
  count: number;
  next: string;
  previous: string;
  results: City[];
}

export function searchCity(city: string): Promise<City[]> {
  return axios
    .get<SearchCityResponse>("/geo/cities/", {
      params: {
        search: city
      }
    })
    .then((response) => {
      return response.data.results;
    })
    .catch(() => {
      return [];
    });
}
