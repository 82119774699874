import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { Form } from "react-bootstrap";
import { ActionMeta, OptionsType, OptionTypeBase } from "react-select";
import Select from "react-select";
import { Themes } from "../../libs/helpers/themeSwitcher";
import useTheme from "../../libs/hooks/useTheme";

interface ActivitiesSelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  name: string; // using as id
  comment?: string;
  label?: string;
  initialActivities?: OptionsType<OptionTypeBase>;
}

export default function ActivitiesSelect({
  name,
  comment,
  label,
  initialActivities,
  ...props
}: ActivitiesSelectProps): ReactElement {
  const theme = useTheme();
  const { getFieldProps, getFieldMeta, getFieldHelpers } = useFormikContext();
  const field = getFieldProps(name);
  const meta = getFieldMeta(name);
  const helpers = getFieldHelpers(name);

  const options: OptionsType<OptionTypeBase> = [
    { value: "culture", label: "culture" },
    { value: "club", label: "club" },
    { value: "food", label: "food" },
    { value: "sport", label: "sport" },
    { value: "tourism", label: "tourism" },
    { value: "hotel", label: "hotel" },
    { value: "shopping", label: "shopping" },
    { value: "misc", label: "other" }
  ];

  const onChange = (option: OptionsType<OptionTypeBase>, action: ActionMeta<OptionTypeBase>) => {
    helpers.setValue(
      option?.map((_option) => ({
        type: _option.value
      }))
    );
  };

  return (
    <Form.Group controlId={name} className="text-left">
      {label && <Form.Label>{label}</Form.Label>}
      <Select
        placeholder="Select activities..."
        noOptionsMessage={() => "No activities to select"}
        {...(props as unknown)}
        onChange={onChange}
        options={options}
        defaultValue={initialActivities}
        isMulti
        styles={{
          menu: (provided, state) => ({
            ...provided,
            backgroundColor: theme === Themes.dark ? "rgb(71, 71, 71)" : "rgb(238, 238, 238)"
          })
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#43a2ab",
            primary75: "#43a2ab",
            primary50: "#43a2ab",
            primary25: "#43a2ab"
          }
        })}
      />
      {comment && <div className="small text-muted">{comment}</div>}
      {meta.error ? (
        <Form.Control.Feedback style={{ display: "block" }} type="invalid">
          {meta.error}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
}
