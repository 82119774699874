import { useFormikContext } from "formik";
import React, { ReactElement } from "react";
import { Form as BSForm, FormProps } from "react-bootstrap";

interface Props extends FormProps {
  children: ReactElement | ReactElement[];
}

export default function Form({ children, ...props }: Props): ReactElement {
  const { handleSubmit } = useFormikContext();

  return (
    <BSForm noValidate onSubmit={handleSubmit} {...props}>
      {children}
    </BSForm>
  );
}
