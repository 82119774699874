import { useFormikContext } from "formik";
import React, { ReactElement, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { ActionMeta, OptionsType, OptionTypeBase } from "react-select";
import AsyncSelect from "react-select/async";
import { searchCity } from "../../libs/apiHelpers/geo";
import { Themes } from "../../libs/helpers/themeSwitcher";
import throttle from "../../libs/helpers/throttle";
import useTheme from "../../libs/hooks/useTheme";

interface CitySelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  name: string; // using as id
  comment?: string;
  label?: string;
  initialCity?: { value: number; label: string };
  onChangeCallback?: (value: { value: number; label: string }) => void;
}

interface OptionType {
  value: number;
  label: string;
}

export default function CitySelect({
  name,
  comment,
  label,
  initialCity,
  onChangeCallback,
  ...props
}: CitySelectProps): ReactElement {
  const theme = useTheme();
  const { getFieldProps, getFieldMeta, getFieldHelpers } = useFormikContext();
  const field = getFieldProps(name);
  const meta = getFieldMeta(name);
  const helpers = getFieldHelpers(name);

  const loadOptions = (inputValue: string, loadCallback: (res: any) => void) => {
    searchCity(inputValue)
      .then((result) => {
        return result.map((city) => ({ value: city.id, label: city.display_name }));
      })
      .then(loadCallback);
  };

  const hasInitialCity = !!initialCity?.value;

  const [value, valueSet] = useState(hasInitialCity ? initialCity : undefined);
  const onChange = (option: OptionType, action?: ActionMeta<OptionTypeBase>) => {
    valueSet(option);
    if (!option) {
      option = { value: undefined, label: undefined };
    }
    helpers.setValue(option.value);
    onChangeCallback && onChangeCallback({ value: option.value, label: option.label });
  };

  useEffect(() => {
    if (hasInitialCity) {
      onChangeCallback && onChangeCallback(initialCity);
    }
  }, []);

  return (
    <Form.Group controlId={name} className="text-left">
      {label && <Form.Label>{label}</Form.Label>}
      <AsyncSelect
        placeholder="Start typing city..."
        noOptionsMessage={() => "No cities to select"}
        {...(props as unknown)}
        isClearable
        value={value}
        onChange={onChange}
        loadOptions={throttle(loadOptions, 500)}
        defaultValue={hasInitialCity ? initialCity : undefined}
        styles={{
          menu: (provided, state) => ({
            ...provided,
            backgroundColor: theme === Themes.dark ? "rgb(71, 71, 71)" : "rgb(238, 238, 238)"
          })
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#43a2ab",
            primary75: "#43a2ab",
            primary50: "#43a2ab",
            primary25: "#43a2ab"
          }
        })}
      />
      {comment && <div className="small text-muted">{comment}</div>}
      {meta.error ? (
        <Form.Control.Feedback style={{ display: "block" }} type="invalid">
          {meta.error}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  );
}
