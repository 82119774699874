import React from "react";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";

export interface MultilineInputProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  name: string; // using as id
  rows?: number;
  comment?: string;
  label?: string;
}

export default function MultilineInput({ label, name, rows = 3, comment, ...props }: MultilineInputProps) {
  const { getFieldProps, getFieldMeta } = useFormikContext();
  const field = getFieldProps(name);
  const meta = getFieldMeta(name);
  return (
    <Form.Group className="text-left" controlId={name}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control as="textarea" rows={rows} {...(props as unknown)} {...field} isInvalid={!!meta.error} />
      {comment && <div className="small text-muted">{comment}</div>}
      {meta.touched && meta.error ? <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback> : null}
    </Form.Group>
  );
}
